<template>
  <div>
    <NavbarGuest />
    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" step="1">
          Enter your Email Id
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 2" step="2">
          Confirmation
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-form ref="emailForm">
            <v-text-field
              v-model="user.email"
              :rules="emailRules"
              label="Enter Email"
            >
            </v-text-field>
          </v-form>

          <v-btn color="primary" @click="validateEmail()"> Continue </v-btn>

          <!-- <v-btn text> Cancel </v-btn> -->
        </v-stepper-content>

        <v-stepper-content step="2">
          <p>
            Password reset link has been successfully sent to the email address
          </p>
          <!-- <v-btn text> Cancel </v-btn> -->
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>



<script>
import NavbarGuest from "../components/NavbarGuest.vue";
import { AuthenticationService } from "./../services/AuthenticationService";
const authenticationService = new AuthenticationService();

export default {
  components: {
    NavbarGuest,
  },
  data() {
    return {
      e1: 1,
      user: {},
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
    };
  },

  methods: {
    validateEmail() {
      if (this.$refs.emailForm.validate()) {
        authenticationService.forgotPassword(this.user).then((res) => {
          console.log(res);
          this.e1 = 2;
        });
      }
    },
  },
};
</script>